<template>
  <div>
    <a-page-header title="推荐内容管理" class="hearStyle" />
    <a-form layout="inline">
      <a-form-item label="榜单项目:">
        <a-select v-model="searchForm.projectId" style="width: 200px" placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" :filter-option="filterOption" show-search>
          <a-select-option v-for="option in projectList" :key="option.id" :value="option.id">
            {{ option.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-input-group compact>
          <a-select v-model="searchForm.contentType" style="width: 100px;">
            <a-select-option v-for="item in contentTypeOptions" :value="item.index" :key="item.index">
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-input v-model="searchForm.commonId" placeholder="请输入" style="width: 200px" allowClear />
        </a-input-group>
      </a-form-item>
      <a-form-item label="状态：">
        <a-select v-model="searchForm.stateType" style="width: 200px" placeholder="全部">
          <a-select-option v-for="option in stateList" :key="option.index" :value="option.index">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
        <a-button v-if="$checkButtonPermissions($buttonPermissionsConfig.ranking.recommend.export)"
          style="margin-left: 15px; background-color: #e6a23c; color: #fff; border: #e6a23c;" @click="exportData">
          导出 </a-button>
      </span>
    </a-form>
    <a-button v-if="$checkButtonPermissions($buttonPermissionsConfig.ranking.recommend.add)" class="mt-15" type="primary"
      @click="addData"> <a-icon type="plus" /> 添加 </a-button>
    <a-table :columns="columns" style="margin-top: 10px" :data-source="tableData" :rowKey="(record, index) => {
      return index;
    }
      " :pagination="pagination.total ? pagination : false" @change="pageChange" bordered>
      <div slot="commonId" slot-scope="text, record" class="action">
        <div>{{ help.ContentTypeTextEnmu[text.contentType] }}</div>
        <div>{{ `${text.commonId ? `（ ID：${text.commonId} ）` : ''}` }}</div>
      </div>
      <div slot="stateType" slot-scope="text, record" class="action">
        <div>{{ help.stateTypeTextEnmu[text.stateType] }}</div>
      </div>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button v-if="$checkButtonPermissions($buttonPermissionsConfig.ranking.recommend.edit)" type="link"
          @click="editList(text.id)">编辑</a-button>
        <a-button v-if="$checkButtonPermissions($buttonPermissionsConfig.ranking.recommend.remove)" type="link"
          @click="deleteData(text.id)">删除</a-button>
      </span>
    </a-table>
    <!-- 添加/编辑 -->
    <AddList v-if="show" :show.sync="show" :actionType="actionType" :projectId="searchForm.projectId"
      :projectName="currentProjectName" @close="show = false" @refresh="getTableList" :id="selectId" />
    <!-- 删除 -->
    <a-modal :visible="showRemoveHint" @cancel="deleteData" :closable="false" :destroyOnClose="false" :footer="false"
      width="20%">
      <div style="height: 80px; line-height: 60px; text-align: center;">是否确认删除？</div>
      <div style="width: 100%; display: flex; justify-content: space-around;">
        <a-button @click="showRemoveHint = false"> 否 </a-button>
        <a-button type="primary" @click="remove"> 是 </a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import * as api from "@/api/ranking";
import AddList from "./add/index"
import help from './help/inxex'
import { mapState } from "vuex";
import { dealExcelRespose } from '@/utils/download'
import pageRules from "@/utils/pagingConfig/pageUtils.js";
import {projectName} from "@/api/serviceBinding";
export default {
  components: { AddList },
  computed: {
    ...mapState({
      projectList: (state) => state.common.projectList
    }),
  },
  watch: {
    'searchForm.projectId': {
      handler(newVal) {
        if (newVal) {
          const currentProjectItem = this.projectList.find(item => item.id === newVal);
          this.currentProjectName = currentProjectItem?.project_name;
        }
      },
      immediate: true,
      deep: true
    },
    projectList: {
      handler(newVal) {
        if (newVal && newVal.length) {
          this.searchForm.projectId = newVal[0].id;
          this.currentProjectName = newVal[0].project_name;
          if (this.init) {
            this.getTableList();
            this.init = false;
          }
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      init: true,
      help,
      show: false,// 添加弹框是否显示
      actionType: 'add',
      currentProjectName: '',
      selectId: '',
      showRemoveHint: false,// 删除天空是否显示
      //状态列表
      stateList: [
        {
          index: 0,
          label: "全部"
        },
        {
          index: 1,
          label: "正常"
        },
        {
          index: 2,
          label: "异常"
        },
      ],
      //搜索类型列表
      contentTypeOptions: [
        {
          index: 0,
          label: "全部"
        },
        {
          index: 1,
          label: "文章"
        },
        {
          index: 2,
          label: "线上商品"
        },
      ],

      projectOptions: [], //弹窗类型下拉值
      searchForm: {
        projectId: undefined, // 项目id
        stateType: undefined, // 状态类型(1：正常，2：异常)
        contentType: 0, // 内容类型(1:文章，2:线上商品)
        commonId: '', // 帖子或商品id
      },
      //分页数据
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      columns: [
        { title: "优先级", dataIndex: "priority", key: "priority", width: 100, align: "center" },
        { title: "内容", key: "commonId", align: "center", scopedSlots: { customRender: "commonId" } },
        { title: "描述", dataIndex: "des", key: "des", align: "center" },
        { title: "状态", key: "stateType", align: "center", scopedSlots: { customRender: "stateType" } },
        { title: "更新时间", dataIndex: "updateTime", key: "updateTime", align: "center" },
        { title: "操作人", dataIndex: "updateUser", key: "updateUser", align: "center" },
      ],
      tableData: [],
      // 列表操作按钮权限配置
      actions: [
        this.$buttonPermissionsConfig.ranking.recommend.edit,
        this.$buttonPermissionsConfig.ranking.recommend.remove,
      ]
    };
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    // 导出
    async exportData() {
      try {
        const res = await api.exportRecommendContent(this.findOrExportDataDispose());
        dealExcelRespose(res.data, '推荐内容数据.xlsx');
      } catch (error) {

      }
    },
    //点击添加打开弹出
    addData() {
      this.actionType = 'add';
      this.show = !this.show;
    },
    //编辑添加打开弹出
    editList(val) {
      this.actionType = 'edit'
      this.selectId = val;
      this.show = !this.show;
    },
    //删除弹框是否显示
    deleteData(val) {
      this.selectId = val;
      this.showRemoveHint = !this.showRemoveHint
    },
    async remove() {
      try {
        const res = await api.removeRecommendContent(this.selectId);
        this.showRemoveHint = !this.showRemoveHint;
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.pagination.current = pageRules.pageRules(
            this.tableData.length,
            1,
            this.pagination.current
          );
          this.getTableList();
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        this.showRemoveHint = !this.showRemoveHint;
        this.$message.error('操作失败，请重试');
      }
    },
    //筛选
    search() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTableList();
    },
    // 筛选重置
    resetSearchForm() {
      this.searchForm = {
        projectId: this.searchForm.projectId,
        stateType: undefined,
        contentType: 0,
        commonId: '',
      }
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.pagination.total = 0;
      this.getTableList();
    },
    //分页操作
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getTableList();
    },

    //获取列表数据
    async getTableList() {
      const res = await api.findRecommendContentList(this.findOrExportDataDispose());
      if (res.code === this.$SUCCESS_CODE) {
        this.tableData = res.data?.items || [];
        this.pagination.total = res.data?.total;
      }
    },
    /**数据处理 */
    // 查询/导出数据处理
    findOrExportDataDispose() {
      return {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.searchForm,
        contentType: this.searchForm.contentType ? this.searchForm.contentType : undefined,
        stateType: this.searchForm.stateType ? this.searchForm.stateType : undefined,
        projectId: this.searchForm.projectId ? this.searchForm.projectId : undefined
      };
    }
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.columns.push(this.$backTableAction({ align: "center" }));
  }
};
</script>
<style lang="scss" scoped>
.mt-15 {
  margin-top: 15px;
}

.hearStyle {
  padding-left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 237, 240);
}

.ant-table-row .ant-btn {
  padding: 0;
  margin: 0 5px;
}

p {
  margin-bottom: 0px;
}
</style>
  